<template>
  <!-- 开始答题 -->
  <el-dialog
    title=""
    :visible.sync="show"
    width="90%"
    :show-close="false"
    @close="close"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="topicDetails"
  >
    <div class="header">
      <span>开始答题</span>
      <i class="el-icon-circle-close" @click="show = false"></i>
    </div>
    <div class="body" v-loading="loading">
      <div
        v-if="
          show &&
          !loading &&
          (answers[question.id] = answers[question.id] || {
            answer: '',
            quest_id: question.id,
          })
        "
      >
        <template v-if="question.type == 2">
          <PDT
            v-model="answers[question.id].answer"
            v-if="question.is_select == 2"
            :alone="true"
            :status="status"
            :optionsData="{
              isAnswer,
              topicData: { ...question, options: detail.options },
            }"
          ></PDT>
          <TKT
            v-model="answers[question.id].answer"
            v-if="question.is_select == 0"
            :alone="true"
            :optionsData="{ isAnswer, topicData: question }"
            :status="status"
          ></TKT>
          <DUOXT
            v-model="answers[question.id].answer"
            v-if="question.is_select == 3"
            :alone="true"
            :optionsData="{
              isAnswer,
              topicData: { ...question, options: detail.options },
            }"
            :status="status"
          ></DUOXT>
          <DXT
            v-model="answers[question.id].answer"
            v-if="question.is_select == 1"
            :alone="true"
            :optionsData="{
              isAnswer,
              topicData: { ...question, options: detail.options },
            }"
            :status="status"
          ></DXT>
        </template>
        <BCT
          v-model="answers[question.id].answer"
          v-if="question.type == 1"
          :alone="true"
          :optionsData="{ isAnswer: false, topicData: question }"
          ref="BCT"
        ></BCT>
        <template v-if="question.type == 3">
          <div class="question_title">
            <span v-html="question.title"></span>
          </div>
          <div v-code-html="question.description"></div>
          <div v-for="(v, i) in detail.children" :key="i">
            <PDT
              v-model="
                (answers[v.id] = answers[v.id] || {
                  answer: '',
                  quest_id: v.id,
                }).answer
              "
              v-if="v.is_select == 2"
              :alone="true"
              :optionsData="{ isAnswer, topicData: v }"
              :status="status"
            ></PDT>
            <TKT
              v-model="
                (answers[v.id] = answers[v.id] || {
                  answer: '',
                  quest_id: v.id,
                }).answer
              "
              v-if="v.is_select == 0"
              :alone="true"
              :optionsData="{ isAnswer, topicData: v }"
              :status="status"
            ></TKT>
            <DUOXT
              v-model="
                (answers[v.id] = answers[v.id] || {
                  answer: '',
                  quest_id: v.id,
                }).answer
              "
              v-if="v.is_select == 3"
              :alone="true"
              :optionsData="{ isAnswer, topicData: v }"
              :status="status"
            ></DUOXT>
            <DXT
              v-model="
                (answers[v.id] = answers[v.id] || {
                  answer: '',
                  quest_id: v.id,
                }).answer
              "
              v-if="v.is_select == 1"
              :alone="true"
              :optionsData="{ isAnswer, topicData: v }"
              :status="status"
            ></DXT>
          </div>
        </template>
      </div>
      <div class="save-btn">
        <div class="save" @click="confirm">提交</div>
        <div class="cancel" @click="close">关闭</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { detail } from "@/api/topic.js";
import PDT from "@/components/TopicType/PDT";
import TKT from "@/components/TopicType/TKT";
import DUOXT from "@/components/TopicType/DUOXT";
import DXT from "@/components/TopicType/DXT";
import BCT from "@/components/TopicType/BCT";
export default {
  components: {
    PDT,
    TKT,
    DUOXT,
    DXT,
    BCT,
  },
  prop: {
    question: {
      type: Object,
      default: {},
    },
    question_id: {
      type: Number,
      default: 0,
    },
  },
  name: "answer",
  data: function () {
    return {
      detail: {},
      question: {},
      loading: false,
      show: false,
      status: "preview",
      isAnswer: false,
      answers: {},
      start_time: 0,
    };
  },
  watch: {
    question_id: function (v, o) {
      this.getInfo();
    },
  },
  mounted() {
    if (this.question_id) {
      this.start();
    } else {
      this.start(this.question);
    }
  },
  methods: {
    start: function (question) {
      if (question) {
        if (!isNaN(question) && question > 0) {
          this.question_id = parseInt(question);
        } else {
          this.question_id = parseInt((question || {}).id);
        }
      }
      if (this.question_id > 0) {
        this.start_time = new Date().getTime() / 1000;
        this.answers = [];
        this.show = true;
        this.getInfo();
      }
    },
    getInfo() {
      if (!(this.question_id > 0) || this.loading) return;
      this.loading = true;
      detail({ id: this.question_id })
        .then((res) => {
          res.data.question.description = res.data.question.description.replace(
            /<img/g,
            "<img style='max-width:100%;height:auto;'"
          );
          console.log(res.data,"天梯详情")
          this.detail = res.data;
          this.question = res.data.question;
          console.log(this.question,"this.question")
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitAnswers(e) {
      this.answers = [Object.assign({},  {...e, quest_id: this.question_id })];
      console.log(this.answers,"this.answersthis.answersthis.answers")
      // this.answers = [Object.assign({}, e, { quest_id: this.question_id })];
    },
    close: function () {
      this.show = false;
      this.$emit("close");
    },
    confirm: function () {
      this.$emit("confirm", {
        question_id: this.question_id,
        answers: Object.values(this.answers),
        start_time: this.start_time,
        type:this.question.type
      });
      this.show = false;
      if (this.question.type == 1) {
        if (this.$refs.BCT) this.$refs.BCT.$children[1].content = "";
      }
    },
  },
};
</script>


<style lang="less" scoped>
::v-deep {
  .el-radio__input.is-checked + .el-radio__label {
    color: #00957e;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: #00957e;
    border-color: #00957e;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #00957e;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #00957e;
    border-color: #00957e;
  }
}
::v-deep {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.header {
  height: 35px;
  background: #00957e;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  line-height: 35px;
  align-items: center;
  .el-icon-circle-close {
    font-size: 20px;
    cursor: pointer;
  }
}
.body {
  padding: 2rem 3rem;
}
.save-btn {
  height: 2rem;
  margin-top: 1rem;
}
.save,
.cancel {
  float: right;
  bottom: 2rem;
  right: 2rem;
  width: 40px;
  cursor: pointer;
  background: #00957e;
  border-radius: 2px;
  color: #fff;
  padding: 6px 20px;
  text-align: center;
}
.cancel {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 2px;
  border: 1px solid #979797;
  padding: 5px 20px;
  margin-right: 0.5rem;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    width: 70%;
    .item {
      padding: 1rem 0rem;
      min-height: 10rem;
    }
    .btn {
      margin-top: 30px;
      text-align: right;
    }
    .course-title {
      padding: 2rem;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #dbdbdb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .edit {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .feedback {
          display: flex;
          align-items: center;
          font-size: 14px;
          cursor: pointer;
          margin-right: 0.8rem;
        }
        .el-icon-edit-outline,
        .el-icon-star-off,
        .el-icon-star-on {
          font-size: 20px;
          margin-right: 0.2rem;
        }
      }
      .name {
        font-size: 18px;
        color: #0a0a0a;
        line-height: 29px;
        margin-bottom: 1rem;
        white-space: pre-wrap;
      }
      .detailed {
        font-size: 14px;
        color: #0a0a0a;
        line-height: 20px;
        > span {
          margin-right: 1.5rem;
        }
      }
    }
    ::v-deep {
      .el-tabs--border-card {
        border-radius: 10px;
      }
      .el-tabs__item {
        font-size: 16px;
        height: 50px;
        width: 130px;
        line-height: 50px;
        text-align: center;
      }
      .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: #00957e;
      }
      .el-tabs__item:hover {
        color: #00957e;
      }
    }
    .result {
      padding: 1rem 0rem;
      .run-result {
        display: flex;
        align-items: center;
      }
      .list-item {
        display: flex;
        font-size: 14px;
        color: #2a2a2a;
        align-items: center;
        margin: 1rem 0;
        .item-right {
          display: flex;
          align-items: center;
          .item-result {
            padding: 4px 6px;
            background-color: #00957e;
            color: #fff;
            margin-left: 0.5rem;
          }
        }
      }
    }
    .introduce-box {
      position: relative;
      .description {
        overflow-x: auto;
      }
      .introduce {
        position: absolute;
        color: #909399;
        right: 2rem;
        top: 0.5rem;
        z-index: 9;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  .content-right {
    width: 27%;
    .margin {
      margin-top: 40px;
    }
  }
}
</style>