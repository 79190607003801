<!-- 阶段 -->
<template>
  <div>
    <div style="display: flex; align-items: center">
      <Breadcrumb></Breadcrumb>
      <div style="font-size: 14px; color: #3e3e3e">
        <span style="margin: 0 5px; color: #c0c4cc">/</span>
        {{ data.info.title }}
      </div>
    </div>
    <div class="problemList">
      <div class="content">
        <div class="box">
          <div class="title">
            <el-button
              type="primary"
              size="mini"
              @click="confirmPass"
              :disabled="data.info.type != 0"
              v-if="data.info.level_pass"
              >{{ data.info.type != 0 ? "已通关" : "确认通关" }}</el-button
            >
            <el-button
              type="primary"
              size="mini"
              :disabled="data.info.type != 0 || !data.info.has_fast_question"
              @click="goKstd"
              v-if="data.info.pass_speed > 0"
              >快速通道</el-button
            >
            <!-- <div class="right" :disabled="data.info.type!=0" @click="goKstd" v-if="data.info.pass_speed > 0">
              
            </div> -->
            <el-button
              type="primary"
              size="mini"
              @click.stop="goDetails"
              v-if="data.info.is_pass != 0 && data.info.type == 2"
              >快速通道答题详情</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click.stop="checkNormalTopic"
              v-if="data.info.is_pass != 0 && data.info.type == 2"
              >{{
                type ? "查看快速通道题目列表" : "查看普通题目列表"
              }}</el-button
            >
          </div>

          <el-table
            ref="multipleTable"
            :data="tableData"
            v-loading="tableLoading"
            tooltip-effect="dark"
            style="width: 100%"
            @row-click="rowDblclick"
          >
            <el-table-column label="题号" align="center" prop="qid">
            </el-table-column>
            <el-table-column label="题目" align="center">
              <template slot-scope="scope">
                <span v-html="scope.row.title"></span>
              </template>
            </el-table-column>
            <el-table-column label="难度" align="center">
              <template slot-scope="scope">{{
                scope.row.diffStr ? scope.row.diffStr : "--"
              }}</template>
            </el-table-column>
            <el-table-column
              label="知识点"
              minWidth="100"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="scope">
                <span
                  class="konwsItem"
                  v-for="(v, i) in scope.row.knows"
                  :key="i"
                >
                  {{ v }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
              <template slot-scope="scope">
                <span style="color: #008992" v-if="scope.row.is_submit == 1"
                  >已做</span
                >
                <span style="color: #ff7d00" v-if="scope.row.is_submit == 0"
                  >未做</span
                >
              </template>
            </el-table-column>
            <el-table-column label="得分" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.user_grade == -2">
                  判题中
                </span>
                <span v-else>
                  {{ scope.row.user_grade + "/" + scope.row.total_grade }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="最近提交时间" minWidth="100" align="center">
              <template slot-scope="scope">{{
                scope.row.submit_time || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" minWidth="100" align="center">
              <template slot-scope="scope">
                <el-button
                  :disabled="data.info.type != 0"
                  type="text"
                  size="mini"
                  @click.stop="goWork(scope.row)"
                  >做题</el-button
                >
                <el-button
                  v-if="scope.row.is_submit == 1"
                  type="text"
                  size="mini"
                  @click.stop="
                    $handleRoute(
                      { log_id: scope.row.log_id, id: scope.row.question_id },
                      'topicRecord',
                      '_blank'
                    )
                  "
                  >答题详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- <ul>
            <li
              v-if="!data.quest_list.length"
              style="
                text-align: center;
                width: 100%;
                display: inline-block;
                line-height: 60px;
                color: #999;
              "
            >
              暂无数据
            </li>
            <li v-for="(item, index) in data.quest_list" :key="index">
              <div v-html="item.title"></div>
              <div>
                <span
                  v-if="
                    (data.info.is_pass === 1 && item.is_pass === 0) ||
                    (item.is_pass !== 1 && item.pass_speed > 0)
                  "
                  class="one"
                  @click="goAnswer(item)"
                  >去答题<i class="el-icon-arrow-right"></i
                ></span>
                <span
                  @click="goAnswer(item)"
                  v-else-if="item.is_pass === 1"
                  class="tow"
                  ><i class="el-icon-check"></i>
                </span>
                <span v-else class="three"><i class="el-icon-lock"></i> </span>
              </div>
            </li>
          </ul> -->
        </div>
      </div>
      <!-- 开始答题 -->
      <ProblemAnswer ref="ProblemAnswer" @confirm="confirm"></ProblemAnswer>
    </div>
  </div>
</template>

<script>
import { checkPointList, dtDanswer, kstdFast } from "@/api/ladder.js";
import ProblemAnswer from "@/components/Problem/answer.vue";

export default {
  //import引入的组件需要注入到对象中才能使用

  components: { ProblemAnswer },
  data() {
    //这里存放数据
    return {
      show: false,
      body_show: true,
      is_confirm: true,
      isAnswer: false,
      tableLoading: false,
      status: "preview",

      name: "",
      id: "",
      data: { info: {}, quest_list: [] },
      detail: {},
      question_id: "",
      logId: 0,
      answerObj: {}, //编程题参数
      tableData: [],
      type: false, //是否查看普通题目
      inverterMonTimer: null,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    this.name = this.$route.query.name;
    this.id = this.$route.query.id;
    this.logId = parseInt(this.$route.query.log_id || 0);
    this.getList(); //关卡详情列表
  },
  //方法集合
  methods: {
    //答题记录查看详情
    look_details(item) {
      this.$router.push({
        path: "/topic/topicRecord",
        query: { log_id: item.id, id: this.detail.question.id },
      });
    },
    // 点击详情
    rowDblclick(row) {
      console.log("跳转单个题目");
      // this.$refs.ProblemAnswer.start(row.question_id);
    },
    // 去答题
    goWork(row) {
      console.log(row, "row");
      if (this.data.info.level_pass) {
        this.$confirm(
          "您当前已获得通关的最低分数，您还希望继续练习吗?",
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            type: "warning",
          }
        )
          .then(() => {
            this.$handleRoute(
              {
                question_id: row.question_id,
                type: "ladder",
                id: this.id,
                level_pass: this.data.info.level_pass,
              },
              "/ladder/answerProblem"
            );
          })
          .catch(() => {});
      } else {
        this.$handleRoute(
          { question_id: row.question_id, type: "ladder", id: this.id },
          "/ladder/answerProblem"
        );
      }
      // return
      // this.$handleRoute({ question_id: row.question_id,type:'ladder',id:this.id  }, '/ladder/answerProblem');
      // this.$refs.ProblemAnswer.start(row.question_id);
    },
    // 去快速通道答题详情
    goDetails() {
      // return
      this.$handleRoute(
        {
          log_id: this.data.info.fast_log_id,
        },
        "/problemList/tiDanRecord"
      );
    },
    // 查看普通题目列表
    checkNormalTopic() {
      this.tableLoading = true;
      this.type = !this.type;
      if (this.type) {
        checkPointList({ id: this.id, type: 2 }).then(({ data }) => {
          this.tableLoading = false;

          this.tableData = data.quest_list;
        });
      } else {
        this.getList();
      }
    },
    //提交
    confirm: function ({ question_id, answers, start_time, type }) {
      console.log(answers, "answers~~~~~~~~~~~~~~");
      console.log("confirm", { question_id, answers, start_time, type });
      if (!answers.length) {
        this.$message({
          type: "info",
          message: "请答题后再提交",
        });
        return;
      }
      if (type == 1) {
        let answerdata = {
          quest_id: question_id,
          answer: answers[0].answer.answer,
          language: answers[0].answer.language,
        };
        this.answerObj = [answerdata];
      }
      dtDanswer({
        id: this.id,
        quest_id: question_id,
        answers: type == 1 ? this.answerObj : answers,
        start_time: start_time,
        last_commit_time: new Date().getTime() / 1000,
      })
        .then((res) => {
          // console.log(res,"resssssssssssss");
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getList(); //关卡详情列表

          if (res.msg == "恭喜通过关卡") {
            this.$router.back();
          }
          // this.startComplateAnswer(res.data.id);
        })
        .finally(() => {});
    },
    // 获取关卡内详情列表
    getList() {
      this.tableLoading = true;

      checkPointList({ id: this.id }).then(({ data }) => {
        this.tableLoading = false;

        this.data = data;
        console.log(this.data.info.level_pass, "this.data.info.level_pass");
        console.log(this.data.info.is_answer, "this.data.info.is_answer");
        if (!this.data.info.level_pass && this.data.info.is_answer != 0) {
          console.log("9999");
          this.$nextTick(() => {
            this.inverterMonTimer = setInterval(() => {
              this.getHasPass();
            }, 1000);
          });
        }
        this.tableData = data.quest_list;
      });
    },
    // 只为更新判题结果出来之后的关卡状态
    getStatusList() {
      this.tableLoading = true;
      checkPointList({ id: this.id }).then(({ data }) => {
        this.tableLoading = false;
        this.data = data;
        this.tableData = data.quest_list;
      });
    },
    // 检测是否通关
    getHasPass() {
      this.$http
        .post("/api/v2_0_0.high_ladder/checkLivePass", {
          id: this.id,
          user_id: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.level_pass) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            clearInterval(this.inverterMonTimer);
            this.inverterMonTimer = null;
            this.$nextTick(() => {
              this.getStatusList();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 确认通关
    confirmPass() {
      // /api/v2_0_0.high_ladder/levelPass
      console.log("确认通关");
      this.$confirm("是否确认通关，通关后将无法继续练习其他题目?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/api/v2_0_0.high_ladder/levelPass", { id: this.id })
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.$router.push("/ladder");
            })
            .catch(({ code, msg }) => {});
        })
        .catch(() => {});
    },
    // 去快速通道
    goKstd() {
      this.$handleRoute(
        { id: this.data.info.id, duration: 1 },
        "/ladder/lane",
        "_blank"
      );
      // kstdFast({ id: this.data.info.id })
      //   .then((res) => {
      //     console.log(res, "快速通道");
      //     if (res.data.questions_list && res.data.questions_list.length == 0) {
      //       this.$message.error("知识点题目不足！");
      //     } else {

      //       // this.$router.push({
      //       //   path: "/ladder/lane",
      //       //   query: { id: this.data.info.id },
      //       // });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err, "errrrrrrrr");
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
      // this.$router.push({
      //   path: "/ladder/lane",
      //   query: { id: this.data.info.id },
      // });
    },
    // 去答题
    goAnswer(v) {
      this.$refs.ProblemAnswer.start(v.question_id);
    },
  },
  beforeDestroy() {
    if (this.inverterMonTimer) {
      clearInterval(this.inverterMonTimer);
      this.inverterMonTimer = null;
    }
  },
  destroyed() {
    this.status = "preview";
    this.isAnswer = true;
    if (this.inverterMonTimer) {
      clearInterval(this.inverterMonTimer);
      this.inverterMonTimer = null;
    }
  },
};
</script>
<style lang="less" scoped>
.konwsItem {
  margin-right: 4px;
  padding: 2px 6px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #dddddd;
  font-size: 12px;
  color: #666666;
  text-align: center;
}
.box {
  background-color: #fff;
  .title {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    .right {
      display: flex;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      height: 20px;
      margin-right: 10px;
      line-height: 20px;
      padding: 4px 10px;
      font-size: 12px;
      display: inline-block;
    }
  }
  ul {
    li {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      height: 60px;
      align-items: center;
      .one {
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .el-icon-arrow-right {
          font-weight: 700;
          font-size: 18px;
        }
      }
      .tow {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 100%;
        background: #00957e;
        margin-right: 20px;
        .el-icon-check {
          font-weight: 800;
          font-size: 18px;
          color: #fff;
        }
      }
      .three {
        margin-right: 20px;

        .el-icon-lock {
          font-weight: 800;
          font-size: 30px;
          color: #fc9567;
        }
      }
    }
  }
}
</style>
